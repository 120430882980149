<template>
  <div>
    <div class="_SelectHeader">
      <div>
        <div class="_Select">
          <span>选择专题：</span>
          <el-select
            v-model="searchTheme"
            @change="getcheckTheme"
            clearable
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in themeList"
              :key="item.theme"
              :label="item.theme"
              :value="item.theme"
            ></el-option>
          </el-select>
        </div>
        <el-button type="success" icon="el-icon-search" @click="getTableData">搜索</el-button>
        <el-button :type="activeBtn" @click="handleChecked">设为主题</el-button>
      </div>
      <div>
        <el-button type="primary" @click="addQuestion">添加题库</el-button>
        <el-tooltip class="item" effect="dark" content="导入文件格式为xlsx" placement="top">
          <el-button class="_ImgBtn" @click="uploadFile" type="primary">
            <img src="../../assets/images/import2.png" />导入
          </el-button>
        </el-tooltip>
        <input
          type="file"
          @change="importFile(this)"
          id="imFile"
          ref="fileBtn"
          style="display: none"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <el-button class="_ImgBtn" @click="exportExcel" type="primary">
          <img src="../../assets/images/import1.png" />导出
        </el-button>
        <el-button class="_ImgBtn" icon="el-icon-delete" @click="deleteList"></el-button>
      </div>
    </div>
    <div class="_TableBox">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="elementHeight"
        :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
        border
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        @row-click="rowClick"
        @selection-change="selectAll"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          type="index"
          :index="(e) => (currentPage - 1) * pageSize + e + 1"
          label="序号"
          width="80"
        ></el-table-column>
        <el-table-column prop="interpretation" label="应知应会"></el-table-column>
        <el-table-column prop="theme" label="专题" width="120"></el-table-column>
        <el-table-column prop label="操作" width="200">
          <template slot-scope="scope">
            <i
              class="el-icon-edit i-popover"
              style="color: #8ac3f5;cursor: pointer"
              @click="modifyItem(scope.row)"
            >修改</i>
            <i
              class="el-icon-delete i-popover"
              style="color: red; padding-left: 10px;cursor: pointer"
              @click="deleteItem(scope.row)"
            >删除</i>
          </template>
        </el-table-column>
      </el-table>

      <el-table
        :data="tableData"
        style="width: 100%;display: none"
        :height="elementHeight"
        :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
        border
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        @row-click="rowClick"
        @selection-change="selectAll"
        id="table"
      >
        <el-table-column
          type="index"
          :index="(e) => (currentPage - 1) * pageSize + e + 1"
          label="序号"
          width="80"
        ></el-table-column>
        <el-table-column prop="interpretation" label="应知应会"></el-table-column>
        <el-table-column prop="theme" label="专题" width="120"></el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[20, 30, 50, 80]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listCount"
        class="_Pagination"
      ></el-pagination>
    </div>
    <Dialog
      :title="isAdd ? '添加题库' : '修改题库'"
      :showTowText="true"
      :showDialog="showDialog"
      width="60%"
      top="10vh"
      @handleReqData="handleReqData"
      @handleClose="handleClose"
      class="add-dialog"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm" label-position="top">
        <el-form-item label="应知应会：" prop="Interpretation">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入应知应会"
            v-model="form.Interpretation"
          ></el-input>
        </el-form-item>
        <el-form-item label="请输入判断题（答案是）：" prop="Question">
          <el-input type="textarea" autosize placeholder="请输入判断题（答案是）" v-model="form.Question"></el-input>
        </el-form-item>
        <el-form-item label="请输入判断题（答案否）：" prop="DisturbQuestion">
          <el-input
            type="textarea"
            autosize
            placeholder="请输入判断题（答案否）"
            v-model="form.DisturbQuestion"
          ></el-input>
        </el-form-item>
        <el-form-item label="请输入选择题：" prop="ChoiceQue">
          <el-input type="textarea" autosize placeholder="请输入选择题" v-model="form.ChoiceQue"></el-input>
        </el-form-item>
        <el-form-item label="请输入选择题答案：" prop="ChoiceQueOption">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入选择题答案"
            v-model="form.ChoiceQueOption"
          ></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="选择题正确答案：" prop="ChoiceQueAnswer">
              <el-input placeholder="请输入" v-model="form.ChoiceQueAnswer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择专题：" prop="Theme">
              <el-select v-model="form.Theme" clearable placeholder="请选择" style="width: 100%">
                <el-option
                  v-for="item in themeList"
                  :key="item.theme"
                  :label="item.theme"
                  :value="item.theme"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- <div class="add-select">
        <div class="add-input">
          <span>选择题正确答案：</span>
          <el-input placeholder="请输入" v-model="form.ChoiceQueAnswer"></el-input>
        </div>
        <div class="_Select">
          <span>选择专题：</span>
          <el-select v-model="form.Theme" clearable placeholder="请选择">
            <el-option
              v-for="item in themeList"
              :key="item.theme"
              :label="item.theme"
              :value="item.theme"
            ></el-option>
          </el-select>
        </div>
      </div>-->
    </Dialog>
  </div>
</template>

<script>
import { exportExcel } from "../../modules/ExportExcel";
import { deleteMethod, get, post, put } from "../../api/http";
import XLSX from "xlsx";
import Dialog from "../../components/dialog/Dialog.vue";
import { saveAs } from "file-saver";
export default {
  components: { Dialog },
  // 定义属性
  data() {
    return {
      elementHeight: 0,
      activeBtn: "",
      themeList: [],
      searchTheme: [],
      tableRowIndex: null,
      tableData: [],
      // 分页
      currentPage: 1,
      pageSize: 20,
      listCount: 0,
      // 增删改
      dataItem: {},
      showDialog: false,
      isAdd: false,
      tableData2: [],
      form: {
        Interpretation: "",
        Question: "",
        DisturbQuestion: "",
        ChoiceQue: "",
        ChoiceQueOption: "",
        ChoiceQueAnswer: "",
        Theme: "",
      },
      checkedDelList: [],
      themeItemId: "",
      themeSelectList: [],
      rules: {
        Interpretation: [
          { required: true, message: "请输入应知应会", trigger: "blur" },
        ],
        Theme: [{ required: true, message: "请选择专题", trigger: "change" }],
      },
    };
  },
  mounted() {
    this.getElementHeight();
    this.getThemeList();
    this.getTableData();
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 200);
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    getcheckTheme() {
      get("/api/SettingTheme/GetIsSelect?Theme=" + this.searchTheme).then(
        (res) => {
          if (res.code == 200) {
            this.activeBtn = res.data ? "primary" : "";
          }
        }
      );
    },
    rowClick(row) {
      this.tableRowIndex = row.index;
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    // 设置主题
    handleChecked() {
      if (this.searchTheme.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择主题",
        });
        return;
      }
      var data = this.searchTheme.join(",");
      this.searchTheme = data.split(",");
      post("/api/SettingTheme?Theme=" + data).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: "success",
            message: "设置成功",
          });
          this.activeBtn = "primary";
        }
      });
    },
    getThemeList() {
      get("/api/ThemeQuestionPool/GetTheme").then((resp) => {
        if (resp.code == 200) {
          this.themeList = resp.data;
        }
      });
    },
    async getTableData() {
      var url1 = "/api/ThemeQuestionPool/GetListPage?";
      var url2 =
        "/api/ThemeQuestionPool/GetByThemePage?Theme=" + this.searchTheme + "&";
      await get(
        (this.searchTheme ? url2 : url1) +
          "limit=" +
          this.pageSize +
          "&page=" +
          this.currentPage
      ).then((resp) => {
        if (resp.code == 200) {
          this.tableData = resp.data;
          this.listCount = resp.count;
        }
      });
    },
    // 导入/导出
    exportExcel() {
      this.$nextTick(async () => {
        this.pageSize = this.listCount;
        await this.getTableData();
        exportExcel("#table", "专题题库.xlsx");
      });
    },
    uploadFile: function () {
      this.$confirm("是否需要标准格式文件?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 创建工作簿
          const workbook = XLSX.utils.book_new();
          // 创建工作表
          const header = [
            "判断题（答案是）",
            "判断题（答案否）",
            "应知应会",
            "选择题",
            "选择题答案",
            "选择题正确答案",
            "专题",
            "风险点",
            "风险应对措施",
          ];
          const worksheet = XLSX.utils.aoa_to_sheet([header]); // 使用aoa_to_sheet创建带有表头的工作表
          // 将工作表添加到工作簿中
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          // 将工作簿转换为二进制数据流
          const data = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          // 创建一个Blob对象并设置数据流
          const blob = new Blob([data], { type: "application/octet-stream" });
          // 使用file-saver保存文件
          saveAs(blob, "标准格式文件.xlsx");
        })
        .catch((action) => {
          if (action === "cancel") {
            // 点击导入按钮
            this.imFile = document.getElementById("imFile");
            this.imFile.click();
          }
        });
    },
    importFile: function () {
      var importDataList = [];
      let obj = this.imFile;
      if (!obj.files) {
        return;
      }
      var f = obj.files[0];
      var reader = new FileReader();
      let $this = this;
      reader.onload = function (e) {
        var data = e.target.result;
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64",
          });
        } else {
          $this.wb = XLSX.read(data, {
            type: "binary",
          });
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        );
        //应知应会、专题不能为空
        var stopExecution = false;
        excelData.map((item, index) => {
          if (stopExecution) return;
          if (
            !Object.prototype.hasOwnProperty.call(item, "应知应会") &&
            index == 0
          ) {
            $this.$message.error("导入文件不符合");
            stopExecution = true;
            return;
          }

          // 如果没有应知应会和判断题，则为合并后的第二行。
          var name1 = "判断题（答案是）";
          var name2 = "判断题（答案否）";
          if (
            !Object.prototype.hasOwnProperty.call(item, "判断题（答案是）") &&
            !Object.prototype.hasOwnProperty.call(item, "应知应会")
          ) {
            excelData[index][name1] = excelData[index - 1][name1];
          }
          if (
            !Object.prototype.hasOwnProperty.call(item, "判断题（答案否）") &&
            !Object.prototype.hasOwnProperty.call(item, "应知应会")
          ) {
            excelData[index][name2] = excelData[index - 1][name2];
          }

          if (!Object.prototype.hasOwnProperty.call(item, "应知应会")) {
            item.应知应会 = excelData[index - 1].应知应会;
          }
          importDataList.push({
            Question: item["判断题（答案是）"],
            DisturbQuestion: item["判断题（答案否）"],
            Interpretation: item.应知应会,
            RiskPoints: "",
            RiskMeasures: "",
            Theme: item.专题,
            ChoiceQue: item.选择题,
            ChoiceQueOption: item.选择题答案,
            ChoiceQueAnswer: item.选择题正确答案,
          });
          $this.tableData.push({
            interpretation: item.应知应会,
            theme: item.专题,
          });
        });
        if (importDataList.length != 0) {
          $this.importData(importDataList);
        } else {
          $this.$message({
            type: "warning",
            message: "导入文件不符合",
          });
          this.$refs.fileBtn.value = null;
        }
      };
      if (this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    importData(list) {
      post("/api/ThemeQuestionPool/BatchAdd", {
        data: JSON.stringify(list),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.isAdd ? "新增成功" : "导入成功",
            });
            this.showDialog = false;
            this.getTableData();
            this.getThemeList();
            this.$refs.fileBtn.value = null;
          } else {
            this.$message.error(resp.message);
            this.$refs.fileBtn.value = null;
          }
        })
        .catch(() => {
          this.$message.error("导入失败");
        });
    },
    // 增删改
    addQuestion() {
      this.showDialog = true;
      this.isAdd = true;
      var form = this.form;
      form.Interpretation = "";
      form.Question = "";
      form.DisturbQuestion = "";
      form.ChoiceQue = "";
      form.ChoiceQueOption = "";
      form.ChoiceQueAnswer = "";
      form.Theme = "";
      this.$nextTick(() => {
        this.$refs["ruleForm"].resetFields();
      });
    },
    handleReqData() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            var list = [];
            this.form.RiskPoints = "";
            this.form.RiskMeasures = "";
            list.push(this.form);
            this.tableData.push({
              interpretation: this.form.Interpretation,
              theme: this.form.Theme,
            });
            this.importData(list);
          } else {
            var form = this.form;
            var data = {
              id: this.dataItem.id,
              question: form.Question,
              disturbQuestion: form.DisturbQuestion,
              interpretation: form.Interpretation,
              choiceQue: form.ChoiceQue,
              choiceQueOption: form.ChoiceQueOption,
              choiceQueAnswer: form.ChoiceQueAnswer,
              riskPoints: "",
              riskMeasures: "",
              theme: form.Theme,
              version: "",
            };
            put("/api/ThemeQuestionPool", data)
              .then((resp) => {
                if (resp.code == 200) {
                  this.$message({
                    type: "success",
                    message: "修改成功",
                  });
                  this.showDialog = false;
                  this.getTableData();
                } else {
                  this.$message.error(resp.message);
                }
              })
              .catch(() => {
                this.$message.error("修改失败");
              });
          }
        }
      });
    },
    handleClose() {
      this.showDialog = false;
    },
    modifyItem(row) {
      this.isAdd = false;
      this.dataItem = row;
      var form = this.form;
      form.Interpretation = row.interpretation;
      form.Question = row.question;
      form.DisturbQuestion = row.disturbQuestion;
      form.ChoiceQue = row.choiceQue;
      form.ChoiceQueOption = row.choiceQueOption;
      form.ChoiceQueAnswer = row.choiceQueAnswer;
      form.Theme = row.theme;
      this.showDialog = true;
    },
    deleteItem(row) {
      this.$confirm("您确认要删除id为“" + row.id + "”的专题？")
        .then(() => {
          deleteMethod("/api/ThemeQuestionPool?Id=" + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.tableRowIndex = null;
                this.tableData.splice(row.index, 1);
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    selectAll(e) {
      this.checkedDelList = e;
    },
    deleteList() {
      if (this.checkedDelList.length == 0) {
        this.$message({
          type: "warning",
          message: "未勾选列表",
        });
        return;
      }
      var idList = [];
      this.checkedDelList.map((i) => {
        idList.push({ QuestionId: i.id });
      });
      this.$confirm("您确定要批量删除当前被勾选的专题？")
        .then(() => {
          post("/api/ThemeQuestionPool/BatchDelete", {
            data: JSON.stringify(idList),
          })
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.checkedDelList = [];
                this.getTableData();
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang='less' scoped>
::v-deep .el-form--label-top .el-form-item__label{
  padding: 0 !important;
}
._SelectHeader {
  justify-content: space-between;
  padding-right: 20px;
  background-color: white;
  & > div {
    display: flex;
    align-items: center;
  }
  /deep/.el-checkbox__inner {
    width: 20px !important;
    height: 20px !important;
  }
  /deep/.el-checkbox__inner::after {
    height: 11px !important;
    left: 7px !important;
  }
}
._Table {
  background-color: white;
}
.add-dialog {
  .add-select {
    display: flex;
    align-items: center;
    .add-input {
      display: flex;
      align-items: center;
      span {
        width: 155px;
      }
    }
  }
  .el-textarea {
    margin-bottom: 10px;
  }
}
._Select {
  width: 450px;
  .el-select {
    width: 350px;
  }
}
/deep/ .el-select__tags {
  flex-wrap: nowrap !important;
  overflow: hidden;
}
</style>